require('ods-tool-kit/dist/js/script.js')

$(function () {

  newsTabsBtnLink();
  jumbotronSpImages();

  $(window).on('resize', function () {
    jumbotronSpImages();
  });

  $('.wpcf7-form input[type=submit],.wpcf7-form button[type=submit]').on('click', function () {
    var thista = this;
    setTimeout(function () {
      $(thista).attr('disabled', 'disabled');
    }, 50)
  })
});

/*
    contactform7のスタイル追加
====================================*/
function setFormPosition() {
  if ($('.wpcf7-form')[0]) {
    $('.wpcf7-form textarea, .wpcf7-form .note').parents('td').prev().css({ 'vertical-align': 'top', 'line-height': '30px' });

    // レスポンスエリアは基本非表示
    if ($('.wpcf7-response-output.wpcf7-display-none')[0]) {
      $('.form-response').addClass('d-none');
    }

    // バリデーションエラーの場合
    if ($('.wpcf7-not-valid-tip')[0]) {
      $('.wpcf7-not-valid-tip').prev().addClass('validation-error');

      if ($('.select-box .wpcf7-not-valid-tip')[0]) {
        $('.select-box').addClass('validation-error');
      } else {
        $('.select-box').removeClass('validation-error');
      }
    }

    if ($('form.wpcf7-form').hasClass('sent')) {
      if ($('.formComplete')[0]) {
        // サンキューページ表示
        $('.formInner').css('display', 'none');
        $('.formComplete').css('display', 'block');
      } else {
        // サンキューページへリダイレクト
        var url = location.href;
        if (url.match(/medical/)) {
          url = url.replace(/contact\/(.*)\/amias-(.*)/g, '$1/medical/$2-thanks.php');
        } else {
          url = url.replace(/#.*/g, '').replace(/$/g, '-thanks');
          location = url;
        }
      }
    } else {
      // お問い合わせフォームの場合はフェードイン
      $('section.contact-form').fadeIn();
    }
  } else if ($('section.contact-form')[0]) {
    $('section.contact-form').fadeIn();
  }
}


/*
    タブ切り替え時のボタンURL修正
====================================*/
function newsTabsBtnLink() {
  if ($('.btn-news')[0]) {
    var url = '';
    $('.nav-link').off('click');
    $('.nav-link').on('click', function () {
      var text = $(this).text();
      var section = $(this).parents('section').attr('id');

      if (text === 'All') {
        if (section === 'index-news') {
          url = '/news';
        } else {
          url = '/investors/irnews';
        }
      } else if (text === 'News') {
        url = '/news/info';
      } else if (text === 'Release') {
        url = '/news/pressrelease';
      } else if (text === 'Media Coverage') {
        url = '/news/media';
      } else if (text === 'Investor Relations') {
        url = '/investors/irnews';
      }

      $('.btn-news a').attr('href', url);
    });
  }
}
